<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto" style="margin-left:10%;">
            <b-link
              style="margin-top:0px"
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                  style="max-width:150px"
                />
              </span>
              <!-- <h4 class="brand-text primary">
                {{ appName }}
              </h4> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenu[0]"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- :items="navMenuItems" -->
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { onMounted, provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import axios from '@axios'
import { forEach } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  data() {
     return {
      navMenu: [],
      // mainProps: { width: 50, height: 50 }
     }
  },
  mounted() {
    this.$root.$on('component1', () => {
      // your code goes here
      this.load_menu()
    })
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  created(){
    this.load_menu()
    // this.timer = setInterval(this.load_menu, 50000); // 30 detik => 30.000
  },
  methods: {
    async load_menu () {
      let result = await axios.post(`${useAppConfig().AppUrl}global/getMenuAkses`, {emp_id: localStorage.getItem('emp_id')})
      // console.log(result.data[0].data);
      let menus = result.data[0].data
      let treeMenu = []
      for (let i = 0; i < menus.length; i++) {
        const element = menus[i];
        console.log(element);
        let result2 = await axios.post(`${useAppConfig().AppUrl}global/getSubMenuAkses`, {emp_id: localStorage.getItem('emp_id'), menu: element.map_hakakses_menu})
        console.log(result2.data[0].data);
        let submenus = result2.data[0].data
        let treeSubMenu = []
        for (let j = 0; j < submenus.length; j++) {
          const element2 = submenus[j];
          treeSubMenu.push(
            {
              title: element2.map_hakakses_submenu,
              route: element2.map_hakakses_route,
            },
          )
        }
        treeMenu.push(
          {
            title: element.map_hakakses_menu, //'Master Data',
            icon: element.map_hakakses_icon,
            children: treeSubMenu
          }
        )
      }
      console.log(treeMenu);
      this.navMenu = []
      this.navMenu.push(treeMenu)
    },
        // cancelAutoUpdate () {
        //     clearInterval(this.timer);
        // }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const navMenu = ref([])
    const timer = ''

    // onMounted(async() => {
    //   let result = await axios.post(`${useAppConfig().AppUrl}global/getMenuAkses`, {emp_id: localStorage.getItem('emp_id')})
    //   // console.log(result.data[0].data);
    //   let menus = result.data[0].data
    //   let treeMenu = []
    //   for (let i = 0; i < menus.length; i++) {
    //     const element = menus[i];
    //     console.log(element);
    //     let result2 = await axios.post(`${useAppConfig().AppUrl}global/getSubMenuAkses`, {emp_id: localStorage.getItem('emp_id'), menu: element.map_hakakses_menu})
    //     console.log(result2.data[0].data);
    //     let submenus = result2.data[0].data
    //     let treeSubMenu = []
    //     for (let j = 0; j < submenus.length; j++) {
    //       const element2 = submenus[j];
    //       treeSubMenu.push(
    //         {
    //           title: element2.map_hakakses_submenu,
    //           route: element2.map_hakakses_route,
    //         },
    //       )
    //     }
    //     treeMenu.push(
    //       {
    //         title: element.map_hakakses_menu, //'Master Data',
    //         icon: element.map_hakakses_icon,
    //         children: treeSubMenu
    //       }
    //     )
    //   }
    //   navMenu.value = treeMenu
    // })

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      navMenu,
      timer
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
